var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "base" },
    [
      _c("div", { staticClass: "card-title" }, [_vm._v("意见反馈")]),
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-cell" }, [
          _vm._m(0),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.title,
                expression: "title"
              }
            ],
            staticClass: "cell-content",
            attrs: { placeholder: "请输入反馈标题", maxlength: "20" },
            domProps: { value: _vm.title },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.title = $event.target.value
              }
            }
          })
        ]),
        _c("div", { staticClass: "card-cell textarea-cell" }, [
          _vm._m(1),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.content,
                expression: "content"
              }
            ],
            staticClass: "cell-content",
            attrs: {
              placeholder: "请输入反馈内容",
              maxlength: "300",
              rows: "4"
            },
            domProps: { value: _vm.content },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.content = $event.target.value
              }
            }
          })
        ])
      ]),
      _c("div", { staticClass: "card-title" }, [_vm._v("反馈图片")]),
      _c("div", { staticClass: "card" }, [
        _c(
          "div",
          { staticClass: "card-cell imgs-cell" },
          [
            _vm._l(_vm.fileList, function(item, index) {
              return _c("div", { key: index, staticClass: "up-img-cell" }, [
                _c("img", {
                  staticClass: "up-img",
                  attrs: { src: _vm.imgUrl(item), alt: "" },
                  on: {
                    click: function($event) {
                      return _vm.showImg(item)
                    }
                  }
                }),
                _c("img", {
                  staticClass: "remove-img",
                  attrs: {
                    src: require("@/assets/img/enterprise/remove.png"),
                    alt: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.onRemoveFile(index)
                    }
                  }
                })
              ])
            }),
            _vm.fileList.length <= 2
              ? _c(
                  "div",
                  { staticClass: "up-img-cell" },
                  [_c("upload-img", { on: { sendOrgImg: _vm.getOrgImg } })],
                  1
                )
              : _vm._e()
          ],
          2
        )
      ]),
      _c(
        "div",
        { staticClass: "but-cell" },
        [
          _c(
            "mt-button",
            {
              staticClass: "save-but",
              attrs: { loading: _vm.submiting, type: "primary" },
              on: { click: _vm.onSubmit }
            },
            [_vm._v("提交")]
          )
        ],
        1
      ),
      _c(
        "mt-popup",
        {
          model: {
            value: _vm.showImgFlag,
            callback: function($$v) {
              _vm.showImgFlag = $$v
            },
            expression: "showImgFlag"
          }
        },
        [_c("img", { staticClass: "big-img", attrs: { src: _vm.tempImgUrl } })]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cell-title" }, [
      _c("span", { staticClass: "required" }, [_vm._v("*")]),
      _c("span", [_vm._v("反馈标题")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cell-title" }, [
      _c("span", { staticClass: "required" }, [_vm._v("*")]),
      _c("span", [_vm._v("反馈内容")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }